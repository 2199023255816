.home {

    &__title {
        position: relative;
        @include flex($direction: column);
        font-size: toRem(60);
        line-height: toRem(55);
        color: $color-text;
        font-weight: $font-weight-bold;
        margin: 0 0 30px;

        &__sub {
            font-size: toRem(22);
            line-height: toRem(25);
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            padding-top: 5px;
            padding-left: 100px;
        }

        &:before {
            content: "";
            @include absolute($right: calc(100% - 85px), $bottom: 10px);
            @include size(100vw, 4px);
            background-color: rgba($color-second, .2);
        }
    }

    &__button {
        height: 50px;
    }

    &__navigation {
        @include flex($direction: row, $alignItems: center);
        @include size(90px, 40px);
        gap: 10px;

        .swiper-button {
            position: unset;
            @include size(40px);
            border-radius: $border-radius--round;
            background-color: $color-third;
            border: 0;
            margin: 0;
            padding: 0;
            transition: background-color $duration $timing;

            &-disabled {
                pointer-events: auto;
            }

            &:before, &:after {
                display: none;
            }

            svg {
                @include size(40px);
                fill: $color-dark;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    
    & #aluShape {
        @include size(100%, auto);
        @include absolute($right: 0, $bottom: -1px, $left: 0);
        z-index: 1;
        pointer-events: none;
    }

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            @include absolute(0, 0, 0, 0);
            z-index: 1;
            height: 100%;
        }
    }

    .slideshow {
        position: relative;
        @include size(100%, 580px);

        &__evellys {
            fill: $color-white;
            @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
            @include size(350px, 150px);
            margin: auto;
            padding-bottom: 10px;
            filter: drop-shadow(0 5px 5px rgba($color-black, .6));
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            @include size(100%);
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);

            &:before {
                content: "";
                z-index: 1;
                @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                margin: auto;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }

        &__content {
            @include flex($direction: column);
            box-sizing: border-box;
            @include absolute($right: 30px, $bottom: 50px);
            @include size(500px, auto);
            padding: 20px;
            background-color: $color-bg-caption;
            color: $color-caption;
        }

        &__title {
            margin-bottom: 15px;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
            @include absolute($top: 0, $right: 0, $left: 0);
            height: 100%;
            pointer-events: none;
    
            &__btn {
                pointer-events: auto;
                @include size(auto);
                margin: 0;
                padding: 0;
                background-color: $arrow-bg;
                border: 0;
                border-radius: 50px;

                &::before, &::after {
                    display: none
                }
    
                svg {
                    @include size(40px);
                    fill: $arrow-color;
                }
    
                &:hover, &:focus {
                    background-color: $arrow-bg--hover;
    
                    svg {
                        fill: $arrow-color--hover;
                    }
                }

                &--prev {
                    left: 15px;
                }

                &--next {
                    right: 15px;
                }
            }
        }

        // Pagination (bullet)
        &__pagination {
            @include absolute($bottom: 45px, $left: 50px);
            @include flex($alignItems: center, $justifyContent: center);
            gap: 5px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                @include size(10px);
                margin: 0;
                background-color: $color-white;
                border-radius: 5px;
                opacity: 1;
                transition: {
                    property: width, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
    
                &:hover, &:focus {
                    background-color: $color-second;
                }
    
                &-active {
                    background-color: $color-second;
                }
            }
        }

        &__autoplayControl {
            @include absolute($bottom: 40px, $left: 15px);
            padding: 0;
            margin: 0 5px 0 0;
            border: 0;
            @include size(20px);
            background-color: $color-second;
            border-radius: $border-radius--round;

            svg {
                @include size(20px);
                fill: $color-dark;
                background-color: $color-second;
                border-radius: $border-radius--round;
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                svg {
                    fill: $color-white;
                    background-color: $color-dark;
                }
            }

            &.autoplayControl {

                &--pause svg:last-child {
                    display: none;
                }
    
                &--play svg {
                    &:first-child {
                        display: none;
                    }
                }
            }
        }
    
    }
}


//======================================================================================================
// Access
//======================================================================================================
.access {

    &__section {
        margin-top: 80px;
        margin-bottom: 100px;
    }

    &__list {
        @include flex($alignItems: center, $justifyContent: center);
        gap: 0 70px
    }

    &__link {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        @include size(125px, 155px);
        
        svg {
            @include size(100px);
            fill: $color-white;
            background-color: $color-fourth;
            border-radius: $border-radius--round;
            margin-bottom: 15px;
            transition: background-color $duration $timing;
        }

        &:hover, &:focus {

            svg {
                background-color: $color-dark;
            }
        }
    }

    &__label {
        font-size: $font-size--text;
        line-height: toRem(20);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        padding: 0;
    }
}


//======================================================================================================
// Events
//======================================================================================================
.events {

    &__section {
        overflow: hidden;

        &--empty {
            display: none;
        }
    }

    &__container {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start);
        column-gap: 30px;
        height: 475px;
    }

    &__item {
        position: relative;
        width: calc(50% - 15px);

        &--1 {
            margin-top: 45px;
        }

        &:hover, &:focus {
            .events {
                &__item {
                    &__title {
                        &:before {
                            width: 80px;
                        }
                    }
                }
            }
        }

        &__container-img {
            position: relative;
            @include size(100%, 260px);
            border-radius: $border-radius;
            overflow: hidden;

            & img {
                @include size(100%);
                object-position: top;
                object-fit: cover;
            }
        
            &--notimg {
                background: url(/wp-content/themes/evellys/assets/src/images/sprite-images/events-icons/events.svg) no-repeat #565c61;
                background-position: 50%;
            }
        }

        &__date {
            @include flex($wrap: wrap, $alignItems: center);
            @include absolute($top: -20px, $right: 40px);
            color: $color-text;
            background-color: $color-second;
            border-radius: 10px;
            font-size: toRem(22);
            line-height: toRem(25);
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            padding: 15px;
        }

        &__title {
            position: relative;
            font-size: toRem(28);
            line-height: toRem(30);
            color: $color-text;
            margin: 0;
            padding: 15px 0;

            &:before {
                content: "";
                @include size(50px, 4px);
                @include absolute($bottom: 0, $left: 0);
                background-color: $color-second;
                transition: width $duration $timing;
            }
        }
    }

    &__button {
        margin: auto 0 0 auto;
    }
}

//======================================================================================================
// Posts
//======================================================================================================
.posts {

    &__section {
        position: relative;
        margin-top: 120px;

        &--empty {
            display: none;
        }

        &:before {
            content: "";
            z-index: -1;
            @include absolute($top: -65px, $left: calc(50% + 420px));
            @include size(325px, 300px);
            background-image: url($urlSpriteImage + "/theme-icons/hermines.svg");
        }
    }

    &__container {
        position: relative;
        @include flex($direction: column);
    }

    &__item {

        &:hover, &:focus {
            .posts {
                &__item {
                    &__title {
                        &:before {
                            width: 80px;
                        }
                    }
                }
            }
        }

        &--0 {
            @include flex($direction: row, $alignItems: flex-start);
        
            .posts {
                &__item {
                    &__container-img {
                        position: relative;
                        z-index: 1;
                        @include size(calc(50% + 85px), 320px);
                        border-radius: $border-radius;
                        overflow: hidden;
    
                        img {
                            @include size(100%);
                            object-fit: cover;
                            object-position: top;
                        }

                        &--notimg {
                            background: url(/wp-content/themes/evellys/assets/src/images/sprite-images/base-icons/posts-icons/post.svg) no-repeat #565c61;
                            background-position: 50%;
                        }
                    }

                    
                    &__content {
                        @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
                        @include size(calc(50% - 35px), 295px);
                        margin-top: -40px;
                        margin-left: -50px;
                        padding: 50px 50px 50px 80px;
                        border-radius: $border-radius;
                        background-color: $color-third-variant;
                    }
                    
                    &__title {
                        position: relative;
                        font-size: toRem(35);
                        line-height: toRem(40);
                        font-weight: $font-weight-bold;
                        padding: 0 0 22px;
                        margin: 0 0 18px;

                        &:before {
                            content: "";
                            @include absolute($bottom: 0, $left: 0);
                            @include size(45px, 4px);
                            background-color: $color-third;
                            transition: width $duration $timing;
                        }
                    }

                    &__intro {
                        font-size: toRem(18);
                        line-height: toRem(25);
                    }
                }
            }
        }
    }

    &__list-small {
        margin-top: 20px;

        .swiper-container {
            height: 200px;
            margin: 0 -15px;
            width: 1200px;
            padding: 10px 15px;
        }

        .swiper-slide {
            width: calc(50% - 15px);
            margin-right: 30px;
        }

        .posts {
            &__item {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                @include size(100%, 180px);
                background-color: $color-white;
                box-shadow: $shadow;
                border-radius: $border-radius;
                padding: 50px 30px;

                &__title {
                    position: relative;
                    font-size: toRem(28);
                    line-height: toRem(30);
                    margin: 0;
                    padding: 0 0 22px;

                    &:before {
                        content: "";
                        @include absolute($bottom: 0, $left: 0);
                        @include size(50px, 4px);
                        background-color: $color-third;
                        transition: width $duration $timing;
                    }
                }
            }
        }
    }

    &__title {

        &:before {
            right: calc(100% - 115px);
            background-color: $color-third-variant;
        }

        &__sub {
            padding-left: 130px;
        }
    }

    &__navigation {
        align-items: flex-end;
        margin: -40px 0 0 auto;

        &--desktop {
            display: none;
        }

        .swiper-button {
            margin-bottom: -5px;
        }
    }

    &__button {
        margin: 20px 0 0 auto;
        border-color: $color-third;
        background-color: $color-third;
    }
}


//======================================================================================================
// Social Wall
//======================================================================================================
.social {

    &__section {
        position: relative;
        overflow: hidden;
        margin-top: 85px;
        padding-bottom: 60px;

        &::before {
            content: '';
            @include absolute($right: 0, $bottom: 0, $left: 0);
            @include size(100%, 240px);
            background-color: $color-second-variant;
        }
    }

    &__title {
        margin: 0;
        pointer-events: none;

        &:before {
            right: calc(100% - 75px);
            background-color: $color-second-variant;
        }

        &__sub {
            padding-left: 90px;
        }
    }

    &__links {
        @include flex($direction: row, $alignItems: flex-end);
        @include size(140px, 0);
        gap: 0 10px;
        margin-left: auto;
    }

    &__link {
        @include size(40px);
        margin-bottom: -5px;

        svg {
            @include size(40px);
            fill: $color-dark;
            border-radius: $border-radius--round;
            background-color: $color-second;
            transition: {
                property: background-color, fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover, &:focus {
            svg {
                fill: $color-white;
                background-color: $color-dark;
            }
        }
    }

    #ff-stream-1 {
        margin-top: 30px;
        height: 270px!important;
        min-height: 270px!important;
        padding: 0;
        background: $color-bg--transparent!important;

        .ff-stream-wrapper {
            width: 1230px;
            margin-top: 0;
            margin-left: -30px;
            padding: 0;
            height: 270px!important;

            .ff-item {
                position: relative;
                @include size(570px, 270px);

                .picture-item__inner {
                    border-radius: 20px;

                    &:before {
                        border-radius: $border-radius;
                    }
                }

                .ff-item-cont {
                    position: relative;
                    display: flex;
                    height: 270px;
                    padding: 20px;

                    .ff-label-wrapper {
                        margin: 15px 15px 0 0;
                        width: 30px;
                        height: 30px;

                        .ff-icon {
                            height: 30px;
                            width: 30px;
                            border-radius: $border-radius--round;
                            background-color: $color-second;

                            &:before {
                                content: none!important;
                            }

                            .ff-icon-inner {
                                height: 30px;
                                width: 30px;
                                right: 0!important;
                                top: 0!important;
                                font-size: 16px!important;
                            }
                        }
                    }

                    .ff-img-holder {
                        @include size(270px, 230px);
                        border-radius: 10px;

                        img {
                            @include size(270px!important, 230px!important);
                            min-height: 230px!important;
                            object-fit: cover!important;
                            border-radius: 10px!important
                        }
                    }

                    .ff-content {
                        position: relative;
                        @include size(240px, 177px);
                        padding: 25px 0 27px;
                        margin: 0 0 0 20px;

                        &:before {
                            content: "";
                            @include absolute($bottom: 0, $left: 0p);
                            @include size(50px, 4px);
                            background-color: $color-second;
                        }
                    }

                    .ff-item-meta {
                        display: none!important;
                    }
                }

                .ff-item-bar {
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    @include size(240px, 30px);
                    margin-left: auto;
                    padding: 6px 0 0;
                    border: 0!important;
                }
            }
        }

        .ff-loadmore-wrapper {
            display: none!important;
        }
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__section {
        overflow: hidden;
        margin: 95px 0 120px;
    }

    & .container {
        @include flex($direction: column, $wrap: wrap, $justifyContent: flex-end);
        height: 465px;
        gap: 0 60px;
    }
    
    &__title {

        &:before {
            right: calc(100% - 60px);
            background-color: $color-main-variant;
        }

        &__sub {
            padding-left: 75px;
        }
    }

    &__container {
        @include flex($direction: column);
        width: calc(50% - 65px);
        gap: 30px;
    }

    &__item {
        border-radius: $border-radius;
        background-color: $color-main-variant;

        .kiosque {

            &__content {
                @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
                padding: 40px 30px;
            }

            &__title {
                font-size: toRem(22);
                line-height: toRem(25);
                font-weight: $font-weight-bold;
                margin: 0 0 15px;
            }

            &__buttons {
                @include flex($direction: row, $alignItems: center);
                gap: 10px;
            }

            &__button {
                @include size(40px);
                background-color: $color-main;
                border-radius: $border-radius--round;
                transition: background-color $duration $timing;

                svg {
                    @include size(40px);
                    fill: $color-text;
                    transition: fill $duration $timing;
                }

                &:hover, &:focus {
                    background-color: $color-dark;
                
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    &__main {
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        width: calc(50% + 5px);
        gap: 0 30px;

        .kiosque {

            &__image {
                @include size(310px, 440px);
                border-radius: $border-radius;
                overflow: hidden;
                box-shadow: $shadow;

                 &--no-image {
                    position: relative;
                    background: $color-light;
                    
                    svg {
                        @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                        margin: auto;
                        fill: $color-gray;
                        @include size(80px);
                    }
                }
            }

            &__content {
                width: 250px;
            }

            &__title {
                font-size: toRem(28);
                line-height: toRem(30);
                margin: 0 0 10px;
            }

            &__date {
                font-size: toRem(16);
                line-height: toRem(25);
                text-transform: capitalize;
            }

            &__buttons {
                @include flex($direction: row, $alignItems: center);
                gap: 10px;
                margin: 30px 0;
            }

            &__button {
                @include size(40px);
                background-color: $color-main;
                border-radius: $border-radius--round;
                transition: background-color $duration $timing;

                svg {
                    @include size(40px);
                    fill: $color-text;
                    transition: fill $duration $timing;
                }

                &:hover, &:focus {
                    background-color: $color-dark;
                
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    &__allbutton {
        @include size(100%, 50px);
        padding: 15px 0;
        margin: 0;
        background-color: $color-main;
    }
}


//======================================================================================================
// Projets
//======================================================================================================
.projects {

    &__section {
        position: relative;
        margin-bottom: 155px;

        &:before {
            content: "";
            @include absolute($top: -100px, $left: calc(50% + 390px));
            @include size(325px, 300px);
            background-image: url($urlSpriteImage + "/theme-icons/hermines.svg");
        }

        .swiper-container {
            position: relative;
            margin-top: -50px;
        }
    }

    &__title {

        &:before {
            right: calc(100% - 105px);
            background-color: $color-third-variant;
        }

        &__sub {
            padding-left: 120px;
        }
    }

    &__content {
        position: relative;
        width: calc(50% - 55px);
        background-color: $color-third-variant;
        border-radius: 20px;
        height: 270px;
        padding: 50px 80px 50px 50px;
        margin-right: -50px;
        margin-top: 50px;
        box-sizing: border-box;

        &__title {
            position: relative;
            font-size: 1.75rem;
            line-height: 1.875rem;
            padding-bottom: 17px;
            margin: 0 0 13px;

            &:before {
                content: "";
                @include size(50px, 4px);
                @include absolute($bottom: 0, $left: 0);
                left: 0;
                bottom: 0;
                background-color: $color-third;
            }
        }
    }

    &__slide {
        display: flex;


        img {
            z-index: 1;
            @include size(calc(50% + 105px), 355px);
            object-fit: cover;
            border-radius: $border-radius;
        }
    }

    &__navigation {
        @include absolute($bottom: 15px, $left: 360px);
    }

    &__button {
        height: 50px;
        margin: 0;
        @include absolute($bottom: -25px, $left: 40px);
        background-color: $color-third;
        border-color: $color-third;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {


    // ALU
    .home-slideshow {

        .slideshow {
            height: 465px;

            &__evellys {
                @include size(300px, 130px);
                padding-bottom: 21px;
            }

            &__autoplayControl {
                left: 10px;
                bottom: 35px;
            }

            &__pagination {
                left: 45px;
                bottom: 40px;
            }
        }
    }

    // Access
    .access {
        &__list {
            gap: 0 40px;
        }

        &__link {
            @include size(120px, 155px);
        }

        &__label {
            font-size: toRem(15);
            line-height: toRem(20);
        }
    }

    // Events
    .events {

        &__container {
            column-gap: 20px;
        }

        &__item {
            width: calc(50% - 10px);
            
            &__title {
                font-size: toRem(25);
                line-height: toRem(30);
            }
        }
    }

    // Posts
    .posts {

        &__section {
            &:before {
                left: calc(50% + 290px);
            }
        }

        &__navigation {
            margin: -35px 0 0 auto;
        }

        &__item {
            &--0 {
                .posts {
                    &__item {
                        &__container-img {
                            @include size(calc(50% + 20px), 300px);
                        }

                        &__content {
                            @include size(calc(50% + 30px), 285px);
                        }

                        &__title {
                            font-size: toRem(30);
                            line-height: toRem(35);
                        }

                        &__intro {
                            font-size: toRem(16);
                            line-height: toRem(25);
                        }
                    }
                }
            }
        }

        &__list-small {
            margin-top: 15px;

            .swiper-container {
                margin: 0 -10px;
                padding: 10px;
                @include size(960px, 160px);
            }

            .swiper-slide {
                width: calc(50% - 10px);
                margin-right: 20px;
            }

            .posts {
                &__item {
                    height: 140px;
                    padding: 30px;

                    &__title {
                        font-size: toRem(25);
                        line-height: toRem(30);
                    }
                }
            }
        }
    }

    // Social
    .social {

        &__section {
            margin-top: 75px;
        }

        #ff-stream-1 {
            .ff-stream-wrapper {
                margin-left: -20px!important;
                width: 980px!important;

                .ff-item {
                    .ff-item-cont {
                        .ff-img-holder {
                            width: 200px!important;
                        }
                        
                        .ff-content {
                            width: 200px!important;
                        }

                        .ff-label-wrapper {
                            @include size(25px!important);
                            margin: 10px 10px 0 0!important;

                            .ff-icon {
                                @include size(25px!important);
                                .ff-icon-inner {
                                    @include size(25px!important);
                                    font-size: 10px!important;
                                }
                            }
                        }
                    }

                    .ff-item-bar {
                        width: 200px!important;
                    }
                }
            }
        }
    }

    // Kiosque
    .kiosque {
        &__section {
            margin-top: 100px;
            margin-bottom: 100px;

            .container {
                gap: 0 30px;
                height: 415px;
            }
        }

        &__container {
            width: calc(50% - 80px);
            gap: 20px;
        }

        &__item {
            .kiosque {
                &__content {
                    padding: 30px;
                }

                &__title {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
            }
        }

        &__main {
            gap: 0 20px;
            width: calc(50% + 50px);

            .kiosque {
                &__image {
                    @include size(250px, 355px);
                }
            
                &__title {
                    font-size: toRem(25);
                    line-height: toRem(30);
                }

                &__date {
                    font-size: toRem(14);
                    line-height: toRem(20);
                }
            }
        }
    }

    // Projects
    .projects {

        &__section {
            &:before {
                top: -75px;
                left: calc(50% + 270px)
            }
        }

        &__content {
            height: 265px;
            width: calc(50% - 20px);
            padding-left: 30px;
        }

        &__slide {
            & img {
                width: calc(50% + 70px);
                height: 350px;
            }
        }

        &__navigation {
            left: 280px;
        }

        &__button {
            left: 30px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // ALU
    .home-slideshow {

        .slideshow {
            height: 320px;

            &__evellys {                
                @include size(230px, 100px);
                padding-bottom: 10px;
            }

            &__autoplayControl {
                bottom: 30px;
            }

            &__pagination {
                bottom: 35px;
            }
        }
    }

    // Access
    .access {

        &__section {
            margin-top: 60px;
            margin-bottom: 80px;
        }

        &__container {
            @include flex($justifyContent: center);
        }

        &__list {
            flex-wrap: wrap;
            gap: 30px 80px;
            width: 520px;
        }

        &__link {
            @include size(120px, 135px);

            svg {
                @include size(80px);
            }
        }
    }

    // Events
    .events {

        &__container {
            height: 425px;
        }

        &__item {

            &__container-img {
                height: 220px;
            }

            &__date {
                right: 30px;
            }

            &__title {
                font-size: toRem(20);
                line-height: toRem(25);
            }
        }
    }

    // Posts
    .posts {
        
        &__section {
            margin-top: 100px;

            &:before {
                left: calc(50% + 120px);
                top: -65px;
            }
        }

        &__item {

            &--0 {
                .posts {
                    &__item {
                        &__container-img {
                            @include size(calc(50% + 15px), 240px);
                        }

                        &__content {
                            margin-left: -60px;
                            margin-top: -25px;
                            width: calc(50% + 45px);
                            padding: 30px 20px 30px 80px;
                            height: 220px;
                        }

                        &__title {
                            font-size: toRem(22);
                            line-height: toRem(25);
                            margin: 0 0 13px;
                            padding: 0 0 17px;
                        }

                        &__intro {
                            font-size: toRem(14);
                            line-height: toRem(20);
                        }
                    }
                }
            }
        }

        &__navigation {
            margin: -30px 0 0 auto;
        }

        &__list-small {
            margin-top: 10px;

            .swiper-container {
                @include size(calc(100% + 20px), 150px);
            }

            .posts {
                &__item {
                    height: 130px;
                    padding: 30px 20px;

                    &__title {
                        font-size: toRem(20);
                        line-height: toRem(25);
                    }
                }
            }
        }
    }

    // Social Wall
    .social {

        &__section {
            &:before {
                height: 290px;
            }
        }

        #ff-stream-1 {
            height: 320px!important;

            .ff-stream-wrapper {
                width: calc(100% + 40px)!important;
                height: 320px!important;
            
                .ff-item {
                    height: 320px!important;

                    .ff-item-cont {
                        height: 320px!important;
                        flex-direction: column;
                        padding: 15px;

                        .ff-img-holder {
                            @include size(100%!important, 140px!important);
                        }
                    
                        .ff-content {
                            @include size(100%!important, 110px!important);
                            margin-left: 0;
                        }
                    }

                    .ff-item-bar {
                        width: calc(100% - 35px)!important;
                    }
                }
            }
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            margin-top: 80px;
            
            .container {
                height: unset;
                justify-content: unset;
            }
        }


        &__container {
            flex-direction: row;
            width: 100%;
        }

        &__item {
            @include size(calc(50% - 10px), 135px);

            .kiosque {
                &__content {
                    padding: 30px 20px;
                }

                &__title {
                    font-size: toRem(18);
                    line-height: toRem(20);
                }
            }
        }

        &__main {
            width: 100%;
            margin-top: 30px;
            justify-content: flex-start;

            .kiosque {
                &__title {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
            }
        }
    }

    // Projects
    .projects {

        &__section {
            margin-bottom: 135px;
            
            &:before {
                left: calc(50% + 60px);
                top: -85px;
            }

            .swiper-wrapper {
                height: 310px;
            }
        }

        &__slide {
            img {
                width: calc(50% + 15px);
                height: 240px;
            }
        }

        &__content {
            height: 235px;
            width: calc(50% + 45px);
            margin-right: -60px;
            padding: 30px 80px 50px 20px;

            &__title {
                font-size: toRem(22);
                line-height: toRem(25);
            }

            &__desc {
                font-size: toRem(14);
                line-height: toRem(20);
            }
        }

        &__button {
            left: 20px;
        }

        &__navigation {
            left: 215px;
            bottom: 5px;
        }
    }
}


// 760
@media screen and (max-width: 760px) {

    // Events
    .events {
        &__container {
            height: unset;
        }

        &__item {
            width: 100%;
        }

        &__button {
            margin: 30px 0 0 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    // Home
    .home {
        &__title {
            font-size: toRem(50);
            line-height: toRem(55);

            &__sub {
                font-size: toRem(20);
                line-height: toRem(25);
            }
        }
    }

    // ALU
    .home-slideshow {

        .slideshow {
            height: 280px;

            &__evellys {
                display: none;
            }

            &__autoplayControl {
                bottom: 25px;
            }

            &__pagination {
                bottom: 30px;
            }
        }
    }

    // Access
    .access {

        &__section {
            margin-bottom: 100px;
        }

        &__list {
            gap: 30px;
            width: 420px;
        }
    }

    // Events
    .events {

        &__item {
            &__date {
                right: 20px;
            }

            &--1 {
                margin-top: 40px;
            }
        }
    }

    // Posts
    .posts {

        &__section {
            margin-bottom: 65px;

            &:before {
                content: none;
            }
        }

        &__list {
            position: relative;
        }

        &__item {
            &--0 {
                flex-direction: column;

                .posts {
                    &__item {
                        &__container-img {
                            @include size(100%, 200px);
                        }

                        &__content {
                            width: 100%;
                            height: 205px;
                            margin-left: 0;
                            margin-top: -50px;
                            padding: 80px 20px 30px;
                        }
                    }
                }
            }
        }

        &__list-small {
            .swiper-container {
                height: 125px;
            }

            .swiper-slide {
                width: 100%;
                margin-right: 20px;
            }

            .posts {
                &__item {
                    height: 105px;
                }
            }
        }

        &__navigation {
            position: absolute;
            bottom: -60px;
            margin: 0;

            &--mobile {
                display: none;
            }

            .swiper-button {
                margin: 0;
            }
        }

        &__button {
            margin: 90px auto 0 0;
        }
    }

    // Socials
    .social {

        &__section {
            &:before {
                height: 630px;
            }
        }

        &__title {
            margin-bottom: 30px;
        }

        &__links {
            height: 40px;
            margin-left: 0;
        }

        &__link {
            margin-bottom: 0;
        }

        #ff-stream-1 {
            height: 660px!important;
            margin-top: 20px!important;

            .ff-stream-wrapper {
                height: 660px!important;
                width: 100% !important;
                gap: 0!important;
                margin: 0!important;

                .ff-item {
                    position: unset!important;
                    transform: unset!important;
                    width: 100% !important;

                    .ff-item-cont {
                        .ff-img-holder {
                            img {
                                width: 100% !important;
                                height: 140px !important;
                                min-height: 140px !important;
                            }
                        }
                    }

                    &#ff-uid-1 {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            margin-top: 100px;
        }

        &__container {
            flex-direction: column;
        }

        &__item {
            height: 140px;
            width: 100%;

            .kiosque {
                &__title {
                    font-size: toRem(20);
                    line-height: toRem(25);
                }
            }
        }

        &__main {
            align-items: center;
            flex-direction: column;

            .kiosque {

                &__image {
                    margin-bottom: 20px;
                }

                &__content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    width: 300px;
                }

                &__title {
                    font-size: toRem(25);
                    line-height: toRem(30);
                }

                &__buttons {
                    margin: 20px 0 30px;
                }
            }
        }

        &__allbutton {
            padding: 15px 30px;
            width: unset;
        }
    }

    // Projects
    .projects {

        &__section {
            margin-bottom: 145px;

            &:before {
                content: none;
            }

            .swiper-container {
                margin-top: 0;
            }

            .swiper-wrapper {
                height: auto;
                padding-bottom: 25px;
            }

            .swiper-slide {
                flex-direction: column;
            }
        }

        &__slide {
            img {
                @include size(100%, 200px);
            }
        }

        &__content {
            order: 1;
            width: 100%;
            height: 305px;
            margin-top: -50px;
            padding: 80px 20px 50px;
        }

        &__button {
            padding: 15px 20px;
        }

        &__navigation {
            left: 190px;
            bottom: 5px;
        }
    }
}

// 360
@media screen and (max-width: $x-small) {
    
    // ALU
    .home-slideshow {

        .slideshow {
            height: 160px;

            &__autoplayControl {
                bottom: 20px;
            }

            &__pagination {
                bottom: 25px;
            }
        }
    }

    // Access
    .access {

        &__section {
            margin-bottom: 80px;
        }
    }
}