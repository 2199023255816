.cover {
    position: relative;
    padding: 60px 0 80px;
    background-color: $color-bg--neutral;

    & #coverShape {
        @include size(100%, auto);
        @include absolute($right: 0, $bottom: 0, $left: 0);
        pointer-events: none;
    }

    &__container {
        @include flex($wrap: wrap, $justifyContent: flex-start);
    }

    &__image-wrapper {
        z-index: 1;
        width: calc(50% - 85px);
        min-height: 300px;
        height: auto;
        max-height: 400px;
        background-color: $color-bg--image;
        border-radius: $border-radius;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        & ~ .cover {
            
            &__informations {
                width: 480px;
                margin-left: 45px;

                .cover__title {
                    &:before {
                        width: 145px;
                    }
                }
            }
        }
    }

    &__informations {
        width: calc(50% + 360px);
        padding: 30px 0;
    }

    &__title {
        position: relative;
        z-index: 0;
        line-height: toRem(50);
        margin: 0 0 10px;

        &:before {
            content: "";
            z-index: -1;
            @include size(100vw, 5px);
            @include absolute($top: 25px, $right: calc(100% + 20px));
            background: $color-second;
            opacity: .5;
        }
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-semibold;
            font-size: toRem(18);
            line-height: toRem(25);
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        padding-bottom: 40px;
        
        &__image-wrapper {
            min-height: unset;
            height: 270px;
            width: 100%;
        }

        &__title {
            font-size: toRem(40);
            line-height: toRem(45);
        }

        &__informations {
            width: 100%!important;
            margin-left: 0!important;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 210px;
        }
    }

}
