.nav-main {
    z-index: 10;
    margin-left: auto;
    margin-right: 80px;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
    }

    // Main menu
    .menu {
        @include flex($wrap: wrap);
        gap: 0 55px;

        &__item {
            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }

                .menu__link {
                    color: $color-text;
                    background-color: $color-bg--transparent;

                    &:before {
                        width: 100%;
                    }
                }
            }
    
            &:last-child {
               .menu__link {
                    &:after {
                        @include size(0);
                        background-color: $color-bg--transparent;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            @include flex($alignItems: center);
            height: 110px;
            padding: 20px 0;
            font-weight: $font-weight-bold;
            background-color: $color-bg--transparent;
            text-transform: uppercase;
            transition: {
                property: background-color, color, height;
                duration: $duration;
                timing-function: $timing;
            }

            &::before {
                content: "";
                @include size(0, 2px);
                @include absolute($right: 0, $bottom: 36px, $left: 0);
                margin: 0 auto;
                background-color: $color-main;
                transition: width $duration $timing;
            }
    
            &:after {
                content: '';
                @include absolute($top: 50%, $right: 0);
                @include size(1px, 100%);
                background-color: $color-white;
                transform: translateY(-50%);
            }
    
            &:hover, &:focus {
                background-color: $color-bg--transparent;
                color: $color-text;

                &:before {
                    width: 100%;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        position: absolute;
        right: 0;
        z-index: 1;
        width: 100%;
        padding: 70px 15px 30px 15px;
        background-color: $color-overlay--rgba;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 15px 30px 15px 0;
            color: $color-white;
            font-weight: $font-weight-bold;
            transition: padding-left $duration $timing;

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-white;
                opacity: .5;
                transition: color $duration $timing;
            }

            &:hover, &:focus {
                padding-left: 15px;

                &::after {
                    opacity: 1;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 35px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::after {
                    right: unset;
                    left: 10px;
                }

                &:hover, &:focus {
                    padding-left: 45px;
                }
            }
        }
        
        &__close {
            @include absolute($top: 20px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            color: $color-white;
            background-color: $color-bg--transparent;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &__icon {
                display: block;
                @include size(26px);
                margin-right: 7px;
                background-color: $color-white;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                fill: $color-text;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-bg--transparent;
                    fill: $color-white;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        margin-right: 60px;
        .menu {
            gap: 0 45px;

            &__link {
                font-size: toRem(15);
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;
        margin-right: 25px;

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 40px 0;
            transition: left $duration $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 5px;
            margin: 0;
            padding: 10px 20px;
            background-color: $color-fourth;
            border: 0;
            border-radius: 50px;
            height: 40px;
            margin: 0;
            font-size: 15px;
            line-height: 20px;
            font-weight: $font-weight-bold; 
            color: $color-text;
            text-transform: uppercase;
            cursor: pointer;

            svg {
                fill: $color-text;
                @include size(20px);
            }

            &:hover, &:focus {
                color: $color-white;
                background-color: $color-dark;

                .nav-main__button {
                    &__icon {
                        background-color: $color-white;
                    
                        &:before, &:after {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 20px;
            height: 2px;
            background-color: $color-dark;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-color: $color-dark;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: $btn-border-width $btn-border-style $color-white;
            border-radius: $border-radius--round;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
                border-bottom: 1px solid rgb(255 255 255 / 30%);
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: none;
                    }

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-white;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;
                transition: none;

                &::before, &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-dark;
            transition: {
                property: visibility, pointer-events, right;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                order: 1;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: none;
                        stroke: $color-dark;
                    }
                }

                &__mobile {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-white;
                padding-left: 0;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}




// 960
@media screen and (max-width: $small) {

    .nav-main {
        position: fixed;
        z-index: 5;
        bottom: 10px;
        left: calc(5% + 10px);
        margin: 0;
    }
}