.footer {
    position: relative;
    background-color: $color-bg--neutral;
    margin-top: 60px;

    & #footerShape {
        @include size(100%, auto);
        @include absolute($right: 0, $bottom: calc(100% - 1px), $left: 0);
        z-index: -1;
        pointer-events: none;
    }

    &__map {
        @include size(770px, 505px);
        @include absolute($top: 0, $bottom: 0, $left: calc(50% + 230px));
    }

    &__container {
        @include flex($direction: column, $wrap: wrap);
        gap: 40px;
        padding-bottom: 80px;
    }

    &__logo {
        @include size(142px, 65px);
        margin-top: 10px;
    }

    &__infos {
        @include flex($alignItems: center);
        gap: 55px;

        .footer {
            &__coord {
                width: 210px;                    

                &:nth-child(2) {
                    .footer__title::before {
                        background-color: $color-third;
                    }
                }

                &:nth-child(3) {
                    width: 215px;
                    
                    .footer__title::before {
                        background-color: $color-main;
                    }
                }
            }
        }
    }

    &__title {
        position: relative;
        @include font-size(22);
        line-height: toRem(25);
        font-weight: $font-weight-bold;
        padding-bottom: 11px;
        margin-bottom: 10px;
    
        &:before {
            content: "";
            @include absolute($bottom: 0, $left: 0);
            @include size(40px, 4px);
            background-color: $color-second;
        }
    }

    &__text {
        font-size: toRem(16);
        line-height: toRem(25);
    }

    &__phone {
        @include flex($alignItems: center);
        gap: 5px;
        text-decoration: underline;
        text-decoration-color: $color-bg--transparent;
        transition: text-decoration-color $duration $timing;

        svg {
            @include size(26px);
        }

        &:hover, &:focus {
            text-decoration-color: $color-dark;
        }
    }

    &__btns {
        @include flex($wrap: wrap, $alignItems: center);
        gap: 65px;
        margin-bottom: 40px;
    }

    &__btn {
        margin: 0;
        height: 50px;
    }

    &__partner {
        @include flex($direction: row, $wrap: wrap, $alignItems: center);
        gap: 100px;

        &__link {
            filter: grayscale(0);
            transition: filter $duration $timing;

            &:first-child {
                @include size(136px, 40px);
            }

            &:nth-child(2) {
                @include size(116px, 40px);
            }

            &:nth-child(3) {
                @include size(60px);
            }

            &:hover, &:focus {
                filter: grayscale(1);
            }
        }
    }

    &__menu {
        padding: 20px 0;
        height: 120px;
        background-color: $color-white;
        @include flex($direction: row, $wrap: wrap, $alignItems: center);

        &__container {
            width: 100%;
        }

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__item {
            position: relative;
            padding: 0;

            &::before {
                content: '';
                @include absolute($top: 50%, $right: -30px);
                @include size(1px, 12px);
                background-color: $color-text;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background-color :transparent;
            }
        }

        &__link {
            color: $color-text;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;
            transition: text-decoration-color $duration $timing;

            &:hover, &:focus {
                color: $color-text;
                text-decoration-color: $color-text;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {

        &__infos {
            gap: 25px;
        }

        &__text, &__phone {
            font-size: toRem(14);
            line-height: toRem(20);
        }

        &__btns {
            gap: 40px;
        }

        &__partner {
            gap: 65px;
        }

        &__map {
            @include size(585px, 490px);
            left: calc(50% + 195px);
            bottom: unset;
            top: 53px
        }

        &__menu {

            &__item {
                &:before {
                    right: -18px;
                }
            }
            
            &__link {
                font-size: toRem(14);
                line-height: toRem(20);
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {

        &__logo {
            margin-top: 20px;
            @include size(120px, 55px);
        }

        &__title {
            font-size: toRem(18);
            line-height: toRem(20);
        }

        &__map {
            display: none;
        }

        &__menu {

            &__list {
                justify-content: unset;
                gap: 15px 50px;
            }

            &__item {
                &:before {
                    right: -27px;
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .footer {
        margin-bottom: 60px;

        &__container {
            padding-bottom: 60px;
        }

        &__infos {
            flex-wrap: wrap;
        }

        &__btns {
            gap: 20px 40px;
        }

        &__partner {
            gap: 20px 35px;
        }

        &__menu {
            height: unset;
        }
    }
}
