header {
    position: fixed;
    z-index: 20;
    @include size(100%, 110px);
    transition: height $duration $timing;
    background-color: $color-white;
    box-shadow: $shadow;

    & ~ main {
        margin-top: 110px;
        transition: margin-top $duration $timing;
    }

    &.header--fixed {
        height: 95px;

        .nav-main {
            .menu {
                &__link {
                    height: 95px;

                    &::before {
                        bottom: 30px;
                    }
                }
            }
        }

        & ~ main {
            margin-top: 95px;
        }
    }

    .header {
        &__container {
            @include flex($alignItems: center);
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(fit-content, 60px);
    margin-top: 10px;

    &:hover, &:focus {
        color: $color-second;
    }

    &__title {
        height: 60px;
        margin: 0;
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        gap: 10px;
        justify-content: center;
        pointer-events: auto;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        margin: 0;
        padding: 0;
        background-color: $color-second;
        border: 0;
        cursor: pointer;

        svg {
            @include size(40px);
            fill: $color-text;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }

        &--search {
            padding-right: 15px;

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .identity {
        height: 50px;
        margin: 0;

        &__title {
            height: 50px;
        
            svg {
                @include size(110px, 50px);
            }
        }
    }

    .tools {
        &__item {
            &--search {
                font-size: 0;
                line-height: 0;
                padding-right: 0;

                svg {
                    margin: 0;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    header {

        & .header {
            &__container {
                height: 100%;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    header {
        position: unset;
        z-index: 10;

        &.header--fixed {
            position: unset;
        }

        &:before {
            content: "";
            @include fixed($right: 0, $bottom: 0, $left: 0);
            z-index: 4;
            height: 60px;
            background-color: $color-white;
            margin: 0 auto;
            box-shadow: $shadow;
        }

        & ~ main {
            margin-top: 0;
        }
    
        & .header {
            &__container {
                justify-content: center;
            }
        }

        .tools {
            position: fixed;
            z-index: 5;
            bottom: 10px;
            right: calc(5% + 10px);
        }
    }
}