//======================================================================================================
// Bloc Portraits
//======================================================================================================
.bloc-portraits {
    &.grid {
        margin-top: 30px;
    }

    .card {
        // &:nth-child(1n+5) {
        //     padding-top: 10px;
        // }

        &__container {
            padding: 0;
            overflow: hidden;
        }

        &__image-wrapper {
            width: 270px;
            background-color: $color-white;

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/silhouette-defaut.png) no-repeat bottom;
            }
            
        }

        &__content {
            @include flex($direction: column, $justifyContent: center);
            padding: 18px 30px;
            background-color: $color-white;
        }

        &__title {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: $font-size--3;
            color: $color--card;
        }

        &__subtitle {
            font-family: $font-family--heading;
            text-transform: uppercase;
            font-size: $font-size--5;
            line-height: 1.2;
        }

        &__description {
            p:not(.card__subtitle) {
                margin-top: 5px;
            }
        }

        &__see-more,
        &__see-less {
            background-color: $color-second;

            &:hover, &:focus {
                background-color: $color-text;
            }
        }

        &__more {
            left: 0;
            padding: 0 30px;
            background-color: $color-white;
        }

        &__links {
            display: flex;
            gap: 5px 25px;
            flex-wrap: wrap;
        }

        &__phone,
        &__mail {
            @include flex($alignItems: center, $justifyContent: center);
            text-align: center;
            line-height: 1.5;
            text-decoration: underline;

            &::before {
                content: '';
                position: relative;
                @include size(30px);
                margin-right: 2px;
                mask-position: center;
                mask-size: contain;
                mask-repeat: no-repeat;
                background-color: $color-dark;
                transition: {
                    property: mask-image, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                text-decoration: none;
            }
        }

        &__mail {
            &::before {
                float: left;
                mask-image: url($urlShort + 'ic-misc-mail.svg');
            }

            @extend %external_linkAfter;
            &::after {
                @include relative($top: 0px);
                float: right;
                margin-left: 2px;
                background-color: $color--card;
            }
        }

        &__phone {
            &::before {
                mask-image: url($urlShort + 'ic-misc-phone.svg');
            }
        }

        &__networks {
            @include flex($alignItems: center, $justifyContent: flex-start);
            margin-top: 30px;

            a {
                @include flex($alignItems: center, $justifyContent: center);
                @include size(30px);
                margin: 0 10px;
                background-color: $color-second;
                border-radius: $btn-spe-border-radius;
                transition: background-color $duration $timing;

                &:hover, &:focus {
                    background-color: $color-dark;

                    &:before {
                        color: $color-white;
                    }
                }

                &::before {
                    font: normal toRem(20)/1 dashicons;
                    position: relative;
                    color: $color-text;
                    transition: color $duration $timing;
                }

                &.facebook {
                    &::before {
                        content: '\f305';
                    }
                }

                &.twitter {
                    &::before {
                        content: '\f301';
                        font-size: $font-size--5;
                        // left: 1px;
                    }
                }

                &.instagram {
                    &::before {
                        content: '\f12d';
                        font-size: $font-size--5;
                        // left: 1px;
                    }
                }

                &.linkedin {
                    &::before {
                        content: '\f18d';
                        font-size: $font-size--5;
                        // left: 1px;
                    }
                }
            }
        }
    }

    &.auto {
        .card {
            &__container {
                @include flex($direction: column, $wrap: inherit);
            }

            &__content {
                align-items: center;
                justify-content: flex-start;
                text-align: center;
                padding: 20px 20px 50px 20px;
            }

            &__title {
                margin-top: 0;
                margin-bottom: 10px;
                font-family: $font-family;
                font-size: $font-size--5;
                color: $color--card;
            }

            &__subtitle {
                font-family: $font-family;
                font-size: $font-size--text;
                text-transform: none;
            }

            &__more {
                justify-content: space-evenly;
                padding: 25px 25px 40px;
            }

            &__links {
                flex-direction: column;
                align-items: center;
            }

            &__phone,
            &__mail {
                height: 30px;
            }

            &__mail {
                width: fit-content;
                width: -moz-fit-content;
                margin-left: 0;
            }

            &__networks {
                justify-content: center;
                margin-top: 0;
            }
        }
    }

    &--portrait {
        .card {
            &__image-wrapper {
                height: 310px;
                min-height: inherit;
            }
        }

        &.auto {
            .card {
                &__container {
                    height: 100%;
                }

                // &__content {
                //     // max-height: 170px;
                // }

                &__more {
                    top: -310px;
                    height: calc(100% + 310px);
                }
            }
        }

        &.full {
            .card {
                &__content {
                    height: auto;
                }
            }
        }
    }

    &--square {
        .card {
            &__image-wrapper {
                height: 270px;
                min-height: inherit;
                background-color: $color-white;
            }
        }

        &.auto {
            .card {
                &__container {
                    height: 100%;
                    line-height: $line-height--heading;
                }

                &__more {
                    top: -270px;
                    height: calc(100% + 270px);
                }
            }
        }

        // &.full {
        //     .card {
        //         // &__content {
        //         //     // max-height: 270px;
        //         // }
        //     }
        // }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .bloc-portraits {
        .card--portraits.col-3_md-6_sm-12 {
            flex-basis: 33.33% !important;
            max-width: 33.33% !important;
        }

        .card__image-wrapper {
            width: 100%;
        }

        &--portrait {
            .card {             
                min-height: calc(530px + 10px);   

                &__image-wrapper {
                    height: 340px;
                    min-height: inherit;
                }
            }

            &.auto {
                .card {
                    // &__container {
                    //     height: 420px;
                    // }

                    // &__content {
                    //     max-height: 170px;
                    // }

                    &__more {
                        top: -340px;
                        height: calc(100% + 340px);
                        padding: 20px 20px 30px;
                    }
                }
            }

            &.full {
                .card {
                    flex-basis: 870px;
                    max-width: 870px;
                    min-height: inherit;

                    &__content {
                        max-height: 310px;
                    }

                    &__image-wrapper {
                        width: 270px;
                        height: 310px;
                    }
                }
            }
        }

        &--square {
            .card {
                min-height: calc(490px + 10px);   

                &__image-wrapper {
                    height: 300px;
                    min-height: inherit;
                }
            }

            &.auto {
                .card {
                    // &__container {
                    //     height: 420px;
                    //     min-height: 220px;
                    // }

                    &__more {
                        top: -300px;
                        height: calc(100% + 300px);
                        padding: 20px 20px 30px;
                    }
                }
            }

            &.full {
                .card {
                    flex-basis: 770px;
                    max-width: 770px;
                    min-height: inherit;

                    &__content {
                        max-height: 300px;
                    }

                    &__image-wrapper {
                        width: 300px;
                        height: 300px;
                    }
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .bloc-portraits {
        .card--portraits.col-3_md-6_sm-12 {
            flex-basis: 50% !important;
            max-width: 50% !important;
        }

        &.full {
            .card {
                flex-basis: 100%;
                max-width: 100%;
                min-height: inherit
            }
        }

        &--portrait {   
            .card {             
                min-height: calc(530px + 20px);   
            }

            &.auto {
                // .card__container {
                //     height: 530px;
                // }

                .card__image-wrapper {
                    height: 340px;
                    min-height: inherit;
                }

                // .card__content {
                //     max-height: 190px;
                // }

                .card__more {
                    top: -340px;
                    height: calc(100% + 340px);
                    padding: 0 15px;
                }
            }
        }

        &--square {
            .card {             
                min-height: calc(490px + 20px);   
            }

            &.auto {
                .card__image-wrapper {
                    height: 300px;
                    min-height: inherit;
                }

                // .card__container {
                //     height: 490px;
                // }

                .card__more {
                    top: -300px;
                    height: calc(100% + 300px);
                    padding: 0 15px;
                }
            }
        }

        .card__image-wrapper {
            width: 100%;

            &--no-image {
                background-position: bottom;
            }
        }

        .card__description {
            p:not(.card__subtitle) {
                font-size: $font-size--text-small;
            }
        }

        .card__content {
            padding: 0 30px 0 20px;
        }

        .card__subtitle {
            font-size: $font-size--text-small;
        }

        .card__links {
            display: flex;
            flex-wrap: wrap;
        }
    }

}


// 640
@media screen and (max-width: $small) {
    
    .bloc-portraits {

        .card__more {
            padding: 0 20px;
        }        

        .card--portraits.col-3_md-6_sm-12 {
            flex-basis: 100% !important;
            max-width: 100% !important;
        }

        &.full {
            .card__image-wrapper {
                width: 100%;
            }

            .card__content {
                height: auto;
                max-height: inherit;
                min-height: 300px;
                padding: 20px 30px 20px 20px;
            }
        }

        &--portrait {
            .card {             
                min-height: inherit;   
            }

            &.full {
                .card__image-wrapper {
                    height: 340px;
                }
            }
        }

        &--square {
            .card {             
                min-height: inherit;   
            }

            &.full {
                .card__image-wrapper {
                    height: 300px;
                }
            }
        }
    }

}
